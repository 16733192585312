import React, { useContext } from "react";

//Context
import { TimeContext, timeConfigs } from "../../config/time";

//MUI
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {MenuItem} from "@mui/material";

export default function SelectPeriod() {
  const setTimeConfig = useContext(TimeContext).setTimeConfig;
  const timeConfig = useContext(TimeContext).timeConfig;
  //const prevTime = localStorage.getItem("timeConfig")??"cyear";

  const handleChange = (event) => {
    const target = event.target;
    let value = target.value;

    localStorage.setItem("timeConfig", value);
    switch (value) {
      case "cyear":
        setTimeConfig(timeConfigs.currentYear);
        break;

      case "lyear":
        setTimeConfig(timeConfigs.lastYear);
        break;

      default:
        setTimeConfig(timeConfigs.currentYear);
        break;
    }
  };

  return (
    <FormControl
        size={"small"}
        variant={"outlined"}
        fullWidth
    >
      <Select
        id="time-config"
        value={timeConfig.value}
        onChange={handleChange}
        aria-label={"Time Range"}
      >
        {[
          { id: "cyear", name: "Current Year" },
          { id: "lyear", name: "Last 12 Months" },
        ].map((element, key) => {
          return (
            <MenuItem key={key} value={element.id}>
              {element.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
