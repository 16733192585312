import {Alert, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import $ from "jquery";
import apiURL from "../../config/environment";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {isNumberPositiveInt} from "../../utils";
import CheckIcon from '@mui/icons-material/Check';

export const EditBikeCounterDialog = ({ handleClose, existingRows, setExistingRows, rowToEdit }) => {
    const [ecoCounterId, setEcoCounterId] = useState(rowToEdit.id);
    const [location, setLocation] = useState(rowToEdit.streetName);
    const [trailType, setTrailType] = useState(rowToEdit.trailType);

    const [ecoCounterIdError, setEcoCounterIdError] = useState(null);
    const [saveError, setSaveError] = useState(false);

    const handleDialogClose = () => {
        setEcoCounterId(null);
        setLocation(null);
        setTrailType(null);
        setSaveError(false);
        setEcoCounterIdError(false);
        handleClose();
    }

    useEffect(() => {
        isNumberPositiveInt(ecoCounterId, "Counter ID", setEcoCounterIdError);
    }, [ecoCounterId])

    useEffect(() => {
        setEcoCounterId(rowToEdit.id);
        setLocation(rowToEdit.streetName);
        setTrailType(rowToEdit.trailType);
    }, [rowToEdit])

    const handleSave = () => {
        if (!isNumberPositiveInt(ecoCounterId, "Counter ID", setEcoCounterIdError)) {
            return
        }

        if (!existingRows.find(row => row.id == ecoCounterId)) {
            setEcoCounterIdError("Counter ID does not exist");
            return;
        }
        const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
        $.ajax({
            method: "PATCH",
            url: apiURL + "admin/site/ecocounter",
            headers: {
                Authorization: `Bearer ${token}`
            },
            contentType: "application/json",
            data: JSON.stringify({
                id: ecoCounterId,
                streetName: location,
                trailType: trailType,
                archived: false
            })
        }).done((data) => {
            const filteredRows = existingRows.filter(row => row.id !== rowToEdit.id);
            setExistingRows([...filteredRows, data])
            handleDialogClose();
        }).fail((jqXHR, textStatus, errorThrows) => {
            console.error("Error adding Bike Counter", jqXHR, textStatus, errorThrows)
            setSaveError(true);
        })
    }

    return (
        <Dialog open={rowToEdit.id} onClose={() => handleDialogClose()}>
            <Box sx={{width: "444px", padding: "16px 24px 24px 24px"}}>
                <DialogTitle sx={{padding: 0}}>Edit Bike Counter</DialogTitle>
                <DialogContent sx={{padding: 0}}>
                    <Box sx={{display:"flex", flexDirection: "column", gap: "16px", marginTop: "16px"}}>
                        {saveError && <Alert severity={"error"}>Unable to save. Please try again later.</Alert>}
                        <TextField
                            label="Eco-Counter ID"
                            error={ecoCounterIdError}
                            helperText={ecoCounterIdError}
                            onChange={(e) => setEcoCounterId(e.target.value)}
                            value={ecoCounterId}
                            disabled
                        />
                        <TextField
                            label="Location"
                            autoFocus
                            onChange={(e) => setLocation(e.target.value)}
                            value={location}
                        />
                        <FormControl fullWidth>
                            <InputLabel id={"trail-type-label"}>Trail Type</InputLabel>
                            <Select
                                value={trailType}
                                labelId={"trail-type-label"}
                                label={"Trail Type"}
                                onChange={(e) => setTrailType(e.target.value)}
                            >
                                <MenuItem value="City">City</MenuItem>
                                <MenuItem value="Regional">Regional</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{padding: 0, marginTop: "16px"}}>
                    <Button onClick={() =>  handleDialogClose()}>Cancel</Button>
                    <Button
                        onClick={(e) => handleSave(e)}
                        disabled={!ecoCounterId || !location || !trailType || isNaN(ecoCounterId)}
                        variant={"contained"}
                        sx={{
                            backgroundColor: 'rgba(25, 118, 210, 1)',
                            gap: "8px"
                        }}
                    >
                        <CheckIcon sx={{width: "16px", height: "24px"}} />
                        Save
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
