import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {IntersectionIcon} from "../icons/IntersectionIcon";
import {SegmentIcon} from "../icons/SegmentIcon";
import {BikeIcon} from "../icons/BikeIcon";
import {useLocation, useNavigate} from "react-router-dom";
import * as React from "react";
import {useEffect} from "react";

export const NavigationTabs = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const setValueWithLocation = () => {
        switch (location.pathname.toLowerCase()) {
            case "/administration/intersections":
                return 0;
            case "/administration/segments":
                return 1;
            case "/administration/bikecounters":
                return 2;
            default:
                return 0;
        }
    }

    const [value, setValue] = React.useState(setValueWithLocation());

    useEffect(() => {
        switch (value) {
            case 0:
                navigate("/administration/intersections");
                break;
            case 1:
                navigate("/administration/segments");
                break;
            case 2:
                navigate("/administration/bikeCounters");
                break;
            default:
                navigate("/administration/intersections");
        }
    }, [value])


    useEffect(() => {
        setValue(setValueWithLocation());
    },[location.pathname])

    return (
        <Tabs
            value={value}
            onChange={ (e, v) => setValue(v)}
            TabIndicatorProps={{style: {backgroundColor: '#FFFFFF'}}}
        >
            <Tab
                label="INTERSECTIONS"
                icon={<IntersectionIcon selected={value === 0} />}
                id="intersections"
                sx={{
                    gap: '4px',
                    paddingRight: '24px',
                    paddingLeft: '24px'
                }}
            />
            <Tab
                label={"SEGMENTS"}
                icon={<SegmentIcon selected={value === 1} />}
                id="segments"
                sx={{
                    gap: '4px',
                    paddingRight: '24px',
                    paddingLeft: '24px'
                }}
            />
            <Tab
                label={"BIKE COUNTERS"}
                icon={<BikeIcon selected={value === 2} />}
                id="bikeCounters"
                sx={{
                    gap: '4px',
                    paddingRight: '24px',
                    paddingLeft: '24px'
                }}
            />
        </Tabs>
    )
}
