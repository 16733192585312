export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

/// This function checks if the value is a positive integer and sets the error message if it is not
export const isNumberPositiveInt = (value, valueName, setValueError, isId) => {
    if (value) {
        if (isNaN(value)) {
            setValueError(`${valueName} must be a number`);
            return false
        } else if (value < 0) {
            setValueError(`${valueName} must be a positive number`);
            return false
        } else if (`${value}`.includes(".")) {
            setValueError(`${valueName} must be an integer`);
            return false
            // eslint-disable-next-line no-undef
        } else if (isId && BigInt(value) > BigInt("9223372036854775807")) {
            setValueError(`${valueName} too large. Must be less than or equal to 9,223,372,036,854,775,807 (9223372036854775807)`);
            return false
        } else {
            setValueError(null)
            return true;
        }
    } else {
        setValueError(null);
        return true;
    }
}


/// This function orders array of object in the admin page by a key and order string and sets the resulting array in the state
export const orderAdminRowsBy = (array, key, order, setArray) => {
    setArray([...array].sort((a, b) => {
        if ((a[key] === null && b[key] === null) || (a[key] === undefined && b[key] === undefined)) {
            return -1;
        }
        if (a[key] === null || a[key] === undefined) {
            return 1;
        }
        if (b[key] === null || b[key] === undefined) {
            return -1;
        }
        if (order === "asc") {
            if (key === "modifiedDate") {
                return new Date(a[key]) - new Date(b[key]);
            } else if (key === "mmaType") {
                if (a[key].includes("Low")) {
                    if (b[key].includes("Low")) {
                        return 0;
                    }
                    return -1;
                }
                if (a[key].includes("Medium")) {
                    if (b[key].includes("Low")) {
                        return 1;
                    }
                    if (b[key].includes("Medium")) {
                        return 0;
                    }
                    return -1;
                }
                if (a[key].includes("High")) {
                    if (b[key].includes("High")) {
                        return 0;
                    }
                    return 1;
                }
            } else if (key === "id") {
                // eslint-disable-next-line no-undef
                return BigInt(a[key].toString()) < BigInt(b[key].toString()) ? -1 : 1;
            } else if (typeof a[key] === "string") {
                return a[key]?.toUpperCase() < b[key]?.toUpperCase() ? -1 : 1;
            }
            return a[key] - b[key];
        } else {
            if (key === "modifiedDate") {
                return new Date(b[key]) - new Date(a[key]);
            } else if (key === "mmaType") {
                if (a[key].includes("Low")) {
                    if (b[key].includes("Low")) {
                        return 0;
                    }
                    return 1;
                }
                if (a[key].includes("Medium")) {
                    if (b[key].includes("Low")) {
                        return -1;
                    }
                    if (b[key].includes("Medium")) {
                        return 0;
                    }
                    return 1;
                }
                if (a[key].includes("High")) {
                    if (b[key].includes("High")) {
                        return 0;
                    }
                    return -1;
                }
            } else if (key === "id") {
                // eslint-disable-next-line no-undef
                return BigInt(b[key].toString()) < BigInt(a[key].toString()) ? -1 : 1;
            } else if (typeof a[key] === "string") {
                return b[key]?.toUpperCase() < a[key]?.toUpperCase() ? -1 : 1;
            }
            return b[key] - a[key];
        }
    }))
}
