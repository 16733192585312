import React from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

//Icons
import CameraIconForCard from "../../components/icons/CameraIconForCard";
import CarDetectorIcon from "../../components/icons/carDetectorIcon";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import TwoCarsIcon from "../../components/icons/TwoCarsIcons";
import SignalIcon from "../../components/icons/signalIcon";
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import BusAlertIcon from "@mui/icons-material/BusAlert";
import PowerIcon from "../../components/icons/powerIcon";
import SpeedIcon from "@mui/icons-material/Speed";

import KPICard from "../../components/dashboard/card";
import SwitchIcon from "../../components/icons/SwitchIcon";
import Copyright from "../../components/copyright";

const thisMonth = new Date(Date.now()).getMonth();
const thisYear = new Date(Date.now()).getFullYear() - 1;

const cards = [
  {
    id: "incidents",
    title: "Traffic Incidents",
    description: "Source: NORCOM 911 calls classified as TA (Traffic Accident)",
    icon: BusAlertIcon,
    valuesEndpoint: "dashboard/incidents/current-year",
    invertColors: true,
    invertArrows: false,
    lastYearValuesEndpoint: "dashboard/incidents/last-year",
    includeUnits: false,
    usePercentage: false,
    tooltipSubTitle: "incidents",
    chartEndpoint: "dashboard/incidents-over-time?timeconfig=",
    subTitle: "Incidents",
    tableEndpoint: "dashboard/most-dangerous-dates?timeconfig=",
    tableTitle: `Worst Dates`,
    tableTitleAddition: `(${thisMonth === 0 ? thisYear : "YTD"})`,
    headerSizes: { xs: [3, 9, 12], sm: [2, 6, 4] },
    tableFooter: "",
    moreInfo: "",
    includeMoreInfo: false,
    type: "traffic",
    showActualNumber: true,
    showMoreDigits: false,
    alterTooltip: false,
    calculateValues: false,
  },
  {
    id: "cyclists",
    title: "Cyclists",
    description:
      "Source: 18 permanent city bicycle counters (regional trails excluded)",
    icon: PedalBikeIcon,
    valuesEndpoint: "dashboard/bicycle/current-year",
    invertColors: false,
    invertArrows: false,
    lastYearValuesEndpoint: "dashboard/bicycle/last-year",
    includeUnits: false,
    usePercentage: false,
    tooltipSubTitle: "cyclists",
    chartEndpoint: "dashboard/bicycles-over-time?timeconfig=",
    subTitle: "Total",
    tableEndpoint: "dashboard/most-traveled-trails?timeconfig=",
    tableTitle: `Top Locations`,
    tableTitleAddition: `(${thisMonth === 0 ? thisYear : "YTD"})`,
    headerSizes: { xs: [3, 9, 12], sm: [2, 6, 4] },
    tableFooter: "",
    moreInfo: "",
    includeMoreInfo: false,
    type: "traffic",
    showActualNumber: false,
    showMoreDigits: false,
    alterTooltip: false,
    calculateValues: false,
  },
  {
    id: "mph",
    title: "PM Travel Speed",
    description:
      "Source: Arterial corridor speed data from Iteris ClearGuide, Mon – Fri, 4–6 PM",
    icon: SpeedIcon,
    valuesEndpoint: "dashboard/travel-time/current-year",
    invertColors: false,
    invertArrows: false,
    lastYearValuesEndpoint: "dashboard/travel-time/last-year",
    includeUnits: true,
    usePercentage: false,
    tooltipSubTitle: "mph",
    chartEndpoint: "dashboard/travel-time-over-time?timeconfig=",
    subTitle: "Average Speed (mph)",
    tableEndpoint: "dashboard/slowest-routes?timeconfig=",
    tableTitle: `Slowest Segments`,
    tableTitleAddition: `(${thisMonth === 0 ? thisYear : "YTD"})`,
    headerSizes: { xs: [3, 9, 12], sm: [2, 6, 4] },
    tableFooter: "",
    moreInfo: "",
    tableTitle2: `Least Reliable Segments`,
    subTitle2: "Planning Time Index",
    includeMoreInfo: false,
    type: "traffic",
    showActualNumber: false,
    showMoreDigits: false,
    alterTooltip: false,
    calculateValues: false,
  },
  {
    id: "min",
    title: "PM Congestion",
    description:
      "Source: SCATS sites deemed to be congested for 15+ minutes, 5 min intervals, Mon – Fri, 4–6 PM",
    icon: TwoCarsIcon,
    valuesEndpoint: "dashboard/congestion-index/current-year",
    invertColors: true,
    invertArrows: false,
    lastYearValuesEndpoint: "dashboard/congestion-index/last-year",
    includeUnits: true,
    usePercentage: false,
    tooltipSubTitle: "min",
    chartEndpoint: "dashboard/minutes-congested-over-time?timeconfig=",
    subTitle: "Avg Total Mins Congested",
    tableEndpoint: "dashboard/congested-sites?timeconfig=",
    tableTitle: `Most Congested Intersections`,
    headerSizes: { xs: [3, 9, 12], sm: [2, 6, 4] },
    tableFooter: "",
    moreInfo: "",
    tableTitle2: "",
    tableTitleAddition: `(${thisMonth === 0 ? thisYear : "YTD"})`,
    subTitle2: "",
    includeMoreInfo: false,
    type: "traffic",
    showActualNumber: false,
    showMoreDigits: false,
    alterTooltip: false,
    calculateValues: false,
  },
  {
    id: "detector-uptime",
    title: "Detector Uptime",
    description: "Source: SCATS vehicle detector alarms, 15-min intervals",
    icon: CarDetectorIcon,
    valuesEndpoint: "dashboard/detector-uptime/current-year",
    invertColors: false,
    invertArrows: false,
    lastYearValuesEndpoint: "dashboard/detector-uptime/last-year",
    includeUnits: false,
    usePercentage: true,
    tooltipSubTitle: "detector-hours down",
    chartEndpoint: "dashboard/detector-uptime-over-time?timeconfig=",
    subTitle: "Hours",
    tableEndpoint: "dashboard/most-detector-down-time?timeconfig=",
    tableTitle: `Most Detector Down Time`,
    tableTitleAddition: `(${thisMonth === 0 ? thisYear : "Last 30 Days"})`,
    headerSizes: { xs: [3, 9, 12], sm: [2, 6, 4] },
    tableFooter: "",
    moreInfo: "",
    tableTitle2: "",
    subTitle2: "",
    includeMoreInfo: false,
    type: "maintenance",
    showActualNumber: false,
    showMoreDigits: true,
    alterTooltip: true,
    calculateValues: true,
  },
  {
    id: "ped-button-uptime",
    title: "Ped Button Uptime",
    description: "Source: SCATS pedestrian-crossing alarms, 15-min intervals",
    icon: DirectionsWalkIcon,
    valuesEndpoint: "dashboard/ped-button-uptime/current-year",
    invertColors: false,
    invertArrows: false,
    lastYearValuesEndpoint: "dashboard/ped-button-uptime/last-year",
    includeUnits: false,
    usePercentage: true,
    tooltipSubTitle: " crossing-hours alarmed",
    chartEndpoint: "dashboard/ped-downtime-over-time?timeconfig=",
    subTitle: "Hours",
    tableEndpoint: "dashboard/most-ped-downtime-sites?timeconfig=",
    tableTitle: `Most Ped Button Down Time`,
    tableTitleAddition: `(${thisMonth === 0 ? thisYear : "Last 30 Days"})`,
    headerSizes: { xs: [3, 9, 12], sm: [2, 6, 4] },
    tableFooter: "",
    moreInfo: "",
    tableTitle2: "",
    subTitle2: "",
    includeMoreInfo: false,
    type: "maintenance",
    showActualNumber: false,
    showMoreDigits: true,
    alterTooltip: true,
    calculateValues: true,
  },
  {
    id: "switch-uptime",
    title: "Switch Uptime",
    description:
      "Source: Switch outages >= 30 min via SolarWinds notifications",
    icon: SwitchIcon,
    valuesEndpoint: "dashboard/switch-uptime/current-year",
    invertColors: false,
    invertArrows: false,
    lastYearValuesEndpoint: "dashboard/switch-uptime/last-year",
    includeUnits: false,
    usePercentage: true,
    tooltipSubTitle: " total site-hours down",
    chartEndpoint: "dashboard/switch-uptime-over-time?timeconfig=",
    subTitle: "Hours",
    tableEndpoint: "dashboard/most-switch-downtime-sites?timeconfig=",
    tableTitle: `Most Switch Down Time`,
    tableTitleAddition: `(${thisMonth === 0 ? thisYear : "Last 30 Days"})`,
    headerSizes: { xs: [3, 9, 12], sm: [2, 6, 4] },
    tableFooter: "",
    moreInfo: "",
    tableTitle2: "",
    subTitle2: "",
    includeMoreInfo: false,
    type: "maintenance",
    showActualNumber: false,
    showMoreDigits: true,
    alterTooltip: false,
    calculateValues: true,
  },
  {
    id: "signal-uptime",
    title: "Signal Uptime",
    description:
      "Source: Sites with SCATS all-red flash alarms, 15-min intervals",
    icon: SignalIcon,
    valuesEndpoint: "dashboard/signal-uptime/current-year",
    invertColors: false,
    invertArrows: false,
    lastYearValuesEndpoint: "dashboard/signal-uptime/last-year",
    includeUnits: false,
    usePercentage: true,
    tooltipSubTitle: "site-hours flashing",
    chartEndpoint: "dashboard/signal-uptime-over-time?timeconfig=",
    subTitle: "Hours",
    tableEndpoint: "dashboard/most-red-flashed-sites?timeconfig=",
    tableTitle: `Most All-Red Flash Time`,
    tableTitleAddition: `(${thisMonth === 0 ? thisYear : "Last 30 Days"})`,
    headerSizes: { xs: [3, 9, 12], sm: [2, 6, 4] },
    tableFooter: "",
    moreInfo: "",
    tableTitle2: "",
    subTitle2: "",
    includeMoreInfo: false,
    type: "maintenance",
    showActualNumber: false,
    showMoreDigits: true,
    alterTooltip: true,
    calculateValues: true,
  },
  {
    id: "power-uptime",
    title: "Power Uptime",
    description: "Source: SCATS External Unit (XU) flag, 15-min intervals",
    icon: PowerIcon,
    valuesEndpoint: "dashboard/power-uptime/current-year",
    invertColors: false,
    invertArrows: false,
    lastYearValuesEndpoint: "dashboard/power-uptime/last-year",
    includeUnits: false,
    usePercentage: true,
    tooltipSubTitle: " total site-hours down",
    chartEndpoint: "dashboard/power-uptime-over-time?timeconfig=",
    subTitle: "Hours",
    tableEndpoint: "dashboard/most-power-downtime-sites?timeconfig=",
    tableTitle: `Most Power Down Time`,
    tableTitleAddition: `(${thisMonth === 0 ? thisYear : "YTD"})`,
    headerSizes: { xs: [3, 9, 12], sm: [2, 6, 4] },
    tableFooter: "",
    moreInfo: "",
    tableTitle2: "",
    subTitle2: "",
    includeMoreInfo: false,
    type: "maintenance",
    showActualNumber: false,
    showMoreDigits: true,
    alterTooltip: false,
    calculateValues: true,
  },
  {
    id: "camera-alerts",
    title: "Camera Alerts",
    description:
      "Source: Axis email alerts for cameras down for 5 minutes or more",
    icon: CameraIconForCard,
    valuesEndpoint: "dashboard/camera-alert/current-year",
    invertColors: true,
    invertArrows: false,
    lastYearValuesEndpoint: "dashboard/camera-alert/last-year",
    includeUnits: false,
    usePercentage: false,
    tooltipSubTitle: "alerts",
    chartEndpoint: "dashboard/most-alerted-camera-sites?timeconfig=ytd",
    subTitle: "Count",
    tableEndpoint: "dashboard/most-alerted-camera-sites?timeconfig=30",
    tableTitle: "Most Alerts (Last 30 Days)",
    headerSizes: { xs: [3, 9, 12], sm: [2, 6, 4] },
    tableFooter: "",
    moreInfo: "",
    tableTitle2: `Most Alerts`,
    tableTitleAddition: `(${thisMonth === 0 ? thisYear : "YTD"})`,
    subTitle2: "",
    includeMoreInfo: false,
    type: "maintenance",
    showActualNumber: false,
    showMoreDigits: false,
    alterTooltip: false,
    calculateValues: false,
  },
];

export default function Dashboard(props) {
  const { isAuthenticated, page } = props;
  return (
    <Box component="main" sx={{ p: 3, bgcolor: "#C1CCD2" }}>
      <Grid container spacing={2}>
        {cards
          .filter((m) => m.type === page)
          .map((info) => (
            <KPICard
              key={info.id}
              info={info}
              isAuthenticated={isAuthenticated}
            />
          ))}
      </Grid>
      <Copyright />
    </Box>
  );
}
