import React from "react";

//Context

//Context

//Material UI
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Copyright from "../../components/copyright";

//Material Icons


export default function EmbedViewer(props) {
  return (
    <Box component="main" sx={{ height: "100%", pb: 4, p: 3 }}>
      <Grid container sx={{ height: "100%" }}>
        <embed
          type={"text/html"}
          src={"https://curblr.turnstone.dev/"}
          width="100%"
        />
      </Grid>
        <Copyright />
    </Box>
  );
}
