import {useEffect, useState} from "react";
import $ from "jquery";
import apiURL from "../../config/environment";
import {Alert, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {isNumberPositiveInt} from "../../utils";
import CheckIcon from '@mui/icons-material/Check';

export const EditIntersectionDialog = ({handleClose, existingRows, setExistingRows, rowToEdit}) => {
    const [siteId, setSiteId] = useState(rowToEdit.id);
    const [location, setLocation] = useState(rowToEdit.shortName);
    const [detectors, setDetectors] = useState(rowToEdit.detectors);
    const [pedButtons, setPedButtons] = useState(rowToEdit.pedButtons * 2);

    useEffect(() => {
        setSiteId(rowToEdit.id);
        setLocation(rowToEdit.shortName);
        setDetectors(rowToEdit.detectors);
        setPedButtons(rowToEdit.pedButtons * 2);
    }, [rowToEdit]);

    const [siteIdError, setSiteIdError] = useState(null);
    const [detectorsError, setDetectorsError] = useState(null);
    const [pedButtonsError, setPedButtonsError] = useState(null);
    const [saveError, setSaveError] = useState(false);

    const handleDialogClose = () => {
        setSiteId(null);
        setLocation(null);
        setDetectors(null);
        setPedButtons(null);
        setSiteIdError(null);
        setDetectorsError(null);
        setPedButtonsError(null);
        setSaveError(false);
        handleClose();
    }

    useEffect(() => {
        isNumberPositiveInt(siteId, "Site ID", setSiteIdError);
        isNumberPositiveInt(detectors, "Detectors", setDetectorsError);
        isNumberPositiveInt(pedButtons, "Pedestrian Buttons", setPedButtonsError);
    }, [siteId, detectors, pedButtons])

    const handleSave = () => {
        if (!isNumberPositiveInt(siteId, "Site ID", setSiteIdError) ||
            !isNumberPositiveInt(detectors, "Detectors", setDetectorsError) ||
            !isNumberPositiveInt(pedButtons, "Pedestrian Buttons", setPedButtonsError)
        ) {
            return;
        }

        if (!existingRows.find(row => row.id == siteId)) {
            setSiteIdError("Site ID does not exists");
            return;
        }
        const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
        $.ajax({
            method: "PATCH",
            url: apiURL + "admin/site/scats",
            headers: {
                Authorization: `Bearer ${token}`
            },
            contentType: "application/json",
            data: JSON.stringify({
                id: siteId,
                shortName: location,
                detectors: detectors,
                pedButtons: (pedButtons * 1) / 2,
                archived: rowToEdit.archived
            })
        }).done((data) => {
            const filteredRows = existingRows.filter(row => row.id !== rowToEdit.id);
            setExistingRows([...filteredRows, data]);
            handleDialogClose();
        }).fail((jqXHR, textStatus, errorThrows) => {
            console.error("Error adding intersection", jqXHR, textStatus, errorThrows)
            setSaveError(true);
        })

    }

    return (
        <Dialog open={rowToEdit} onClose={() => handleDialogClose()}>
            <Box sx={{width: "444px", padding: "16px 24px 24px 24px"}}>
                <DialogTitle sx={{padding: 0}} id="form-dialog-title">Edit Intersection</DialogTitle>
                <DialogContent sx={{padding: 0}}>
                    <Box  sx={{display: "flex", flexDirection: "column", gap: "16px", marginTop: "16px"}}>
                        {saveError && <Alert severity={"error"}>Unable to save. Please try again later.</Alert>}
                        <TextField
                            autoFocus
                            id="name"
                            type="text"
                            fullWidth
                            label="Site ID"
                            value={siteId}
                            onChange={(e) => setSiteId(e.target.value)}
                            error={siteIdError}
                            helperText={siteIdError}
                            disabled={true}
                        />
                        <TextField
                            id="id"
                            type="text"
                            fullWidth
                            label="Location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />

                        <Box display={"flex"} gap={"16px"}>
                            <TextField
                                error={detectorsError}
                                helperText={detectorsError}
                                type="number"
                                label="Detectors"
                                value={detectors}
                                onChange={(e) => setDetectors(e.target.value)}
                                fullWidth
                                disabled
                            />
                            <TextField
                                error={pedButtonsError}
                                helperText={pedButtonsError}
                                type="number"
                                label="Ped Buttons"
                                value={pedButtons}
                                onChange={(e) => setPedButtons(e.target.value)}
                                fullWidth
                                inputProps={{
                                    min: 0,
                                    step: 2,
                                }}
                                disabled
                                />
                        </Box>

                    </Box>
                </DialogContent>
                <DialogActions sx={{padding: 0, marginTop: "16px"}}>
                    <Button onClick={() => handleDialogClose()} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={(e) => handleSave(e)}
                        disabled={!siteId || !location || !detectors || !pedButtons || siteIdError || detectorsError || pedButtonsError}
                        variant={"contained"}
                        sx={{
                            backgroundColor: 'rgba(25, 118, 210, 1)',
                            gap: "8px"
                        }}
                    >
                        <CheckIcon sx={{width: "16px", height: "24px"}}/>
                        Save
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
