export const IntersectionIcon = ({selected}) => {
    return (

        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.33329 1.66665L8.33329 8.33331L1.66663 8.33331L1.66663 6.66665L6.66663 6.66665L6.66663 1.66665L8.33329 1.66665Z" fill={ selected ? "white" : "rgba(255, 255, 255, 0.7)" }/>
            <path d="M11.6666 8.33331L11.6666 1.66665L13.3333 1.66665L13.3333 6.66665L18.3333 6.66665L18.3333 8.33331L11.6666 8.33331Z" fill={ selected ? "white" : "rgba(255, 255, 255, 0.7)" }/>
            <path d="M1.66663 13.3333L6.66663 13.3333L6.66663 18.3333L8.33329 18.3333L8.33329 11.6666L1.66663 11.6666L1.66663 13.3333Z" fill={ selected ? "white" : "rgba(255, 255, 255, 0.7)" }/>
            <path d="M13.3333 13.3333L18.3333 13.3333L18.3333 11.6666L11.6666 11.6666L11.6666 18.3333L13.3333 18.3333L13.3333 13.3333Z" fill={ selected ? "white" : "rgba(255, 255, 255, 0.7)" }/>
        </svg>
    )
}
