import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import $ from "jquery";
import apiURL from "../../config/environment";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { isNumberPositiveInt } from "../../utils";
import CheckIcon from '@mui/icons-material/Check';


export const EditSegmentDialog = ({ handleClose, existingRows, setExistingRows, rowToEdit }) => {
    const [iterisRoute, setIterisRoute] = useState(rowToEdit.id);
    const [location, setLocation] = useState(rowToEdit.name);
    const [pmaDensity, setPmaDensity] = useState(rowToEdit.mmaType);

    const [saveError, setSaveError] = useState(false);

    const handleCloseDialog = () => {
        setIterisRoute(null);
        setLocation(null);
        setPmaDensity(null);
        setSaveError(false);
        handleClose();
    }

    useEffect(() => {
        setIterisRoute(rowToEdit.id);
        setLocation(rowToEdit.name);
        setPmaDensity(rowToEdit.mmaType);
    }, [rowToEdit])

    const [iterisRouteError, setIterisRouteError] = useState(null);

    useEffect(() => {
        isNumberPositiveInt(iterisRoute, "Route ID", setIterisRouteError);
    }, [iterisRoute])

    const handleSave = () => {
        if (!isNumberPositiveInt(iterisRoute, "Route ID", setIterisRouteError)) {
            return
        }

        if (!existingRows.find(row => row.id == iterisRoute)) {
            setIterisRouteError("Route ID does not exist");
            return;
        }
        const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
        $.ajax({
            method: "PATCH",
            url: apiURL + "admin/site/iteris",
            headers: {
                Authorization: `Bearer ${token}`
            },
            contentType: "application/json",
            data: JSON.stringify({
                id: iterisRoute,
                name: location,
                mmaType: pmaDensity,
                archived: false
            })
        }).done((data) => {
            const filteredRows = existingRows.filter(row => row.id !== rowToEdit.id);
            setExistingRows([...filteredRows, data])
            handleCloseDialog();
        }).fail((jqXHR, textStatus, errorThrows) => {
            console.error("Error adding Segment", jqXHR, textStatus, errorThrows)
            setSaveError(true);
        })
    }

    return (
        <Dialog open={rowToEdit} onClose={() => handleCloseDialog()}>
            <Box sx={{ width: "444px", padding: "16px 24px 24px 24px" }}>
                <DialogTitle sx={{ padding: 0 }}>Edit Segment</DialogTitle>
                <DialogContent sx={{ padding: 0 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", marginTop: "16px" }}>
                        {saveError && <Alert severity={"error"}>Unable to save. Please try again later.</Alert>}
                        <TextField
                            label="Iteris Route"
                            type={"text"}
                            error={iterisRouteError}
                            helperText={iterisRouteError}
                            value={iterisRoute}
                            onChange={(e) => setIterisRoute(e.target.value)}
                            fullWidth
                            disabled
                        />
                        <TextField
                            label="Location"
                            type={"text"}
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            fullWidth
                            autoFocus
                        />
                        <FormControl fullWidth>
                            <InputLabel id={"pma-density-label"}>PMA Density</InputLabel>
                            <Select
                                labelId={"pma-density-label"}
                                label="PMA Density"
                                onChange={(e) => setPmaDensity(e.target.value)}
                                value={pmaDensity}
                                fullWidth
                            >
                                <MenuItem value="Low Density">Low</MenuItem>
                                <MenuItem value="Medium Density">Medium</MenuItem>
                                <MenuItem value="High Density">High</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ padding: 0, marginTop: "16px" }}>
                    <Button onClick={() => handleCloseDialog()}>Cancel</Button>
                    <Button
                        onClick={(e) => handleSave(e)}
                        disabled={!iterisRoute || !location || !pmaDensity || iterisRouteError}
                        variant={"contained"}
                        sx={{
                            backgroundColor: 'rgba(25, 118, 210, 1)',
                            gap: "8px"
                        }}
                    >
                        <CheckIcon sx={{ width: "16px", height: "24px" }} />
                        Save
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
