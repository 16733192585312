import { Link, Typography } from "@mui/material";
import React from "react";

export default function Copyright(props) {
  return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{
            marginTop: "16px",
            marginBottom: 0
        }}
        {...props}
      >
          {`Copyright © `}<Link color="inherit" href="https://strataresearch.ca/">{`Strata Research`}</Link>{` 2022-${new Date().getFullYear()}`}
      </Typography>
  );
}
