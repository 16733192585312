import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
    InputAdornment,
    OutlinedInput,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead, TableRow,
    TableSortLabel,
    Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import apiURL from "../../config/environment";
import $ from "jquery";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import {AddIntersectionDialog} from "./addIntersectionDialog";
import {formatDate, orderAdminRowsBy} from "../../utils";
import {EditIntersectionDialog} from "./editIntersectionDialog";
import {useNavigate} from "react-router-dom";
import Copyright from "../copyright";
import {OrderIcon} from "../icons/OrderIcon";

export const IntersectionsTab = () => {
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('id')
    const [rows, setRows] = useState([])
    const [sortedRows, setSortedRows] = useState([])
    const [filteredRows, setFilteredRows] = useState([])
    const [searchText, setSearchText] = useState('')
    const [openAddIntersectionDialog, setOpenAddIntersectionDialog] = useState(false)
    const [openEditIntersectionDialog, setOpenEditIntersectionDialog] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
        $.ajax({
            method: "GET",
            url: apiURL + "admin/sites/scats",
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).done((data) => {
            orderAdminRowsBy(data, orderBy, order, setRows)
        }).fail((res) => {
            if (res.status === 401) {
                localStorage.removeItem("userInfo");
                navigate("/login");
            }
        })
    }, [])

    useEffect(() => {
        if (searchText.length && filteredRows.length) {
            orderAdminRowsBy(filteredRows, orderBy, order, setFilteredRows)
            return
        }
        orderAdminRowsBy(rows, orderBy, order, setRows)
    }, [order, orderBy, searchText])

    useEffect(() => {
        orderAdminRowsBy(rows, orderBy, order, setSortedRows)
        if (searchText && searchText.length > 0) {
            const filtered = rows.filter(row => {
                return row.id.toString().includes(searchText) ||
                    row.shortName.toLowerCase().includes(searchText.toLowerCase())
            })
            orderAdminRowsBy(filtered, orderBy, order, setFilteredRows)
        }
    }, [rows])

    useEffect(() => {
        if (!searchText || searchText === '') {
            setFilteredRows([])
            return
        }
        const filtered = rows.filter(row => {
            return row.id.toString().includes(searchText) ||
                row.shortName.toLowerCase().includes(searchText.toLowerCase())
        })
        setFilteredRows(filtered)
    }, [searchText])

    const handleSearch = (e) => {
        const search = e.target.value
        setSearchText(search)
    }

    const handleClearSearch = () => {
        setSearchText('')
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const headCells = [
        { id: 'id', numeric: true, disablePadding: false, label: 'Site ID' },
        { id: 'shortName', numeric: false, disablePadding: false, label: 'Location' },
        { id: 'detectors', numeric: true, disablePadding: false, label: 'Detectors' },
        { id: 'pedButtons', numeric: true, disablePadding: false, label: 'Ped Buttons' },
        { id: 'modifiedDate', numeric: false, disablePadding: false, label: 'Last Modified' },
        { id: 'modifiedBy', numeric: false, disablePadding: false, label: 'Modified By' },
    ]

        const getRowsForDisplay = (rowArr) => {
            return rowArr.map((row) => (
                <TableRow
                    hover
                    key={row.id}
                    onClick={() => setOpenEditIntersectionDialog(row)}
                    sx={{cursor: 'pointer'}}
                >
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.id}</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.shortName}</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.detectors}</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.pedButtons * 2}</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.modifiedDate ? formatDate(new Date(row.modifiedDate)) : ''}</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.modifiedBy}</TableCell>
                </TableRow>
            ))
        }


    return (

        <Box component="main" sx={{ p: 3, bgcolor: "#C1CCD2", padding: "36px 32px 16px 32px"}}>
            <Card sx={{ borderRadius: "8px" }}>
                <CardContent sx={{padding: "16px 32px"}}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box display={"flex"} alignItems={"center"} gap={"32px"}>
                            <h2><Typography variant={'h5'}>{"Intersections"}</Typography></h2>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                                endAdornment={
                                    searchText.length > 0 && <InputAdornment position="end" onClick={handleClearSearch}>
                                        <IconButton>
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                placeholder={"Search"}
                                sx={{
                                    width: "300px",
                                    height: "40px"
                                }}
                                onChange={handleSearch}
                                value={searchText}
                            />
                        </Box>
                        <Box display={"flex"} alignItems={"center"}>
                            <Button
                                variant={"contained"}
                                startIcon={<AddIcon />} sx={{
                                textTransform: 'none',
                                backgroundColor: 'rgba(19, 151, 203, 1)',
                                borderRadius: "6px",
                                fontSize: "15px",
                                fontWeight: 600,
                                letterSpacing: "0.15px"
                            }}
                                onClick={() => setOpenAddIntersectionDialog(true)}
                            >
                                {"New Intersection"}

                            </Button>
                            <AddIntersectionDialog
                                open={openAddIntersectionDialog}
                                handleClose={() => setOpenAddIntersectionDialog(false)}
                                handleSave={() => setOpenAddIntersectionDialog(false)}
                                existingRows={rows}
                                setExistingRows={setRows}
                            />
                        </Box>
                    </Box>
                    <Box><Typography variant={"body2"}  sx={{color: "grey"}}>{searchText.length > 0 ? filteredRows.length : rows.length} items</Typography></Box>
                    <Box>
                        <TableContainer sx={{height: "calc(100vh - 292px)", minHeight: "500px", overflowX: "auto", marginTop: "8px"}}>
                            <Table stickyHeader>
                                <EditIntersectionDialog
                                    handleClose={() => {
                                        setOpenEditIntersectionDialog(false);
                                    }}
                                    existingRows={rows}
                                    setExistingRows={setRows}
                                    rowToEdit={openEditIntersectionDialog}
                                />
                                <TableHead sx={{backgroundColor: 'rgba(250, 250, 250, 1)'}}>
                                    {
                                        headCells.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align={headCell.align}
                                                sortDirection={orderBy === headCell.id ? order : false}
                                                sx={{
                                                    width: headCell.id === 'shortName' ? 'auto' : '1%',
                                                    whiteSpace: 'nowrap',
                                                    '& path': {
                                                        fill: orderBy === headCell.id ? 'black' : 'none',
                                                        fillOpacity: '0.56'
                                                    },
                                                    '&:hover path': {
                                                        fill: orderBy === headCell.id ? 'black' : 'grey',
                                                        fillOpacity: '0.56',
                                                        transition: 'fill 0.2s'
                                                    },
                                                    backgroundColor: 'rgba(250, 250, 250, 1)'
                                                }}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={() => handleRequestSort(headCell.id)}
                                                    IconComponent={() => (<IconButton
                                                        sx={{
                                                            marginLeft: "4px",
                                                            padding: 0,
                                                            transition: 'transform 0.2s',
                                                    }}
                                                        disableRipple
                                                    >
                                                        <OrderIcon order={order} />
                                                    </IconButton>)}
                                                >
                                                    {headCell.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))
                                    }
                                </TableHead>
                                <TableBody>
                                    {
                                        getRowsForDisplay(searchText.length ? filteredRows : sortedRows)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </CardContent>
            </Card>
            <Copyright />
        </Box>
    )
}
