import React, { useContext } from "react";

//Context
import { TimeContext } from "../../config/time";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { useTheme } from "@mui/material/styles";

//Icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

//Selfmade
import LastYearKPIValues from "./lastYearValues";
import KPIHeader from "./header";
import KPIValues from "./values";
import KPIChart from "./chart";
import KPITable from "./table";

import { Link } from "react-router-dom";

import PMCongestionChart from "./pmCongestionChart";
import TravelSpeedChart from "./travelSpeedChart";
import UptimeChart from "./uptimeChart";
import CustomTable from "./customTable";

const defaultValues = {
  id: "traffic-incidents",
  title: "Traffic Incidents",
  description: "Source: NORCOM 911 calls classified as TA (Traffic Accident)",
  tooltipSubTitle: "",
  actualPeriodValue: 0,
  actualPeriodValuePercentage: 0,
  actualPeriodInterval: "",
  actualPeriodDescription: "Year to date",
  lastYearPeriodValue: 0,
  lastYearPeriodInterval: "",
  lastYearPeriodVariation: 0,
  lastYearPeriodDescription1: "Compared to same",
  lastYearPeriodDescription2: "period last year",
  lastMonthPeriodValue: 0,
  lastMonthPeriodInterval: "",
  lastMonthPeriodValuePercentage: 0,
  lastMonthPeriodDescription: "Last 30 days",
  previousMonthPeriodValue: 0,
  previousMonthPeriodInterval: "",
  previousMonthPeriodVariation: 0,
  previousMonthPeriodDescription: "Compared to previous 30 days",
  previousMonthPeriodDescription1: "Compared to previous",
  previousMonthPeriodDescription2: "30 days",
  thisMonthLastYearPeriodValue: 0,
  thisMonthLastYearPeriodInterval: "",
  thisMonthLastYearPeriodVariation: 0,
  thisMonthLastYearPeriodDescription: "Compared to same 30 days last year",
  thisMonthLastYearPeriodDescription1: "Compared to same 30",
  thisMonthLastYearPeriodDescription2: "days last year",
  lastYearPeriodValuePercentage: 0,
  thisMonthLastYearPeriodPercentage: 0,
  previousMonthPeriodPercentage: 0,
};

const lastYeardefaultValues = {
  id: "traffic-incidents",
  title: "Traffic Incidents",
  description: "Source: NORCOM 911 calls classified as TA (Traffic Accident)",
  actualPeriodValue: 0,
  actualPeriodInterval: "",
  actualPeriodDescription: "9/2/2021 - 5/30/2022",
  previousPeriodInterval: "",
  previousPeriodVariation: 0,
  previousPeriodValue: 0,
  previousPeriodDescription: "Compared to previous period",
  monthlyAverage: 0,
  monthlyAverageDescription: "Monthly",
  weeklyAverage: 0,
  weeklyAverageDescription: "Weekly",
  dailyAverage: 0,
  dailyAverageDescription: "Daily",
  secondaryValuesDescription: "Averages for the Period",
};

export default function KPICard(props) {
  const timeConfig = useContext(TimeContext).timeConfig;
  const { isAuthenticated } = props;
  const theme = useTheme();

  const { info } = props;
  return (
    <Grid item sm={12} md={6} xl={4}>
      <Card sx={{ height: "100%", borderRadius: "8px" }}>
        <CardContent>
          <Grid container spacing={2}>
            <KPIHeader
              Icon={info.icon}
              title={info.title}
              description={info.description}
              headerSizes={info.headerSizes}
            />

            {/* Divider */}
            <Grid item sm={12}>
              <Divider
                variant="fullWidth"
                sx={{
                  backgroundColor: theme.palette.primary.light,
                }}
              />
            </Grid>

            {/* First row */}
            {timeConfig.value === "cyear" ? (
              <KPIValues
                showActualNumber={info.showActualNumber}
                calculateValues={info.calculateValues}
                tooltipSubTitle={info.tooltipSubTitle}
                showMoreDigits={info.showMoreDigits}
                usePercentage={info.usePercentage}
                includeUnits={info.includeUnits}
                endpoint={info.valuesEndpoint}
                defaultValues={defaultValues}
                invertColors={info.invertColors}
                invertArrows={info.invertArrows}
                alterTooltip={info.alterTooltip}
                type={info.id}
              />
            ) : (
              <LastYearKPIValues
                showActualNumber={info.showActualNumber}
                tooltipSubTitle={info.tooltipSubTitle}
                showMoreDigits={info.showMoreDigits}
                usePercentage={info.usePercentage}
                includeUnits={info.includeUnits}
                endpoint={info.lastYearValuesEndpoint}
                defaultValues={lastYeardefaultValues}
                invertColors={info.invertColors}
                invertArrows={info.invertArrows}
                type={info.id}
              />
            )}

            {/* Divivder */}
            <Grid item sm={12}>
              <Divider variant="fullWidth" />
            </Grid>

            {["camera-alerts"].includes(info.id) ? (
              <Grid container item sm={12} sx={{ maxHeight: "564px" }}>
                <CustomTable
                  tableTitleAddition={""}
                  endpoint={info.tableEndpoint}
                  subTitle={info.subTitle}
                  title={info.tableTitle}
                />
                <CustomTable
                  tableTitleAddition={info.tableTitleAddition}
                  endpoint={info.chartEndpoint}
                  subTitle={info.subTitle}
                  title={info.tableTitle2}
                />
              </Grid>
            ) : ["mph"].includes(info.id) ? (
              <TravelSpeedChart
                endpoint={info.chartEndpoint}
                xAxisTitle={info.subTitle}
                id={info.id}
              />
            ) : [
                "signal-uptime",
                "detector-uptime",
                "ped-button-uptime",
                "power-uptime",
                "switch-uptime",
              ].includes(info.id) ? (
              <UptimeChart
                endpoint={info.chartEndpoint}
                xAxisTitle={info.subTitle}
                id={info.id}
              />
            ) : ["min"].includes(info.id) ? (
              <PMCongestionChart
                endpoint={info.chartEndpoint}
                xAxisTitle={info.subTitle}
                id={info.id}
              />
            ) : (
              <KPIChart
                endpoint={info.chartEndpoint}
                xAxisTitle={info.subTitle}
                id={info.id}
              />
            )}

            {["camera-alerts"].includes(info.id) ? null : (
              <KPITable
                tableTitleAddition={info.tableTitleAddition}
                includeAverage={info.id === "cyclists"}
                filterInfo={info.id === "mph"}
                duplicate={info.id === "mph"}
                timeConfig={timeConfig.value}
                endpoint={info.tableEndpoint}
                footer={info.tableFooter}
                subTitle={info.subTitle}
                moreInfo={info.moreInfo}
                title={info.tableTitle}
                filter={0}
              />
            )}

            {info.id === "mph" ? (
              <KPITable
                tableTitleAddition={info.tableTitleAddition}
                includeAverage={info.id === "cyclists"}
                duplicate={info.id === "mph"}
                timeConfig={timeConfig.value}
                endpoint={info.tableEndpoint}
                footer={info.tableFooter}
                subTitle={info.subTitle2}
                moreInfo={info.moreInfo}
                title={info.tableTitle2}
                filterInfo
                filter={1}
                reverse
              />
            ) : null}
          </Grid>
        </CardContent>

        {info.includeMoreInfo && isAuthenticated ? (
          <CardActions sx={{ mt: 2, mb: 2 }}>
            <Grid container justifyContent={"flex-end"}>
              <Link
                to={isAuthenticated ? info.moreInfo : "/login"}
                style={{ textDecoration: "none" }}
              >
                <Grid container>
                  <Typography color={theme.palette.primary.light}>
                    {info.tableFooter}
                  </Typography>
                  <ChevronRightIcon
                    sx={{ color: theme.palette.primary.light }}
                  />
                </Grid>
              </Link>
            </Grid>
          </CardActions>
        ) : null}
      </Card>
    </Grid>
  );
}
